import { type PageScreen } from 'types/page_screen';
import { getRequest } from 'utils/rest-api-util';
import { StringUtil } from 'utils/string-util';

export type PageScreensResponse = {
  pageScreens: PageScreen[];
};

export const getPageScreens = async (
  analyticId: string,
  searchParams?: URLSearchParams,
): Promise<PageScreensResponse> => {
  const requestPath = `/api/v1/analytics/${analyticId}/page_screens`;

  const response = await getRequest(requestPath, searchParams);

  return StringUtil.snakeObjectToCamelObject(
    await response.json(),
  ) as PageScreensResponse;
};
